<template>
  <div>
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <v-form ref="form" v-model="valid" lazy-validation>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Tên:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  size="sm"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  placeholder="Nhập tên"
                  class="mt-2"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Doanh nghiệp:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  class="mt-2 select-style"
                  size="sm"
                  v-model="$v.form.selectedCompany.$model"
                  :options="listCompany"
                  :state="validateState('selectedCompany')"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >-- Doanh nghiệp --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  >Vui lòng chọn doanh nghiệp</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Tên viết tắt:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  size="sm"
                  v-model="$v.form.shortName.$model"
                  :state="validateState('shortName')"
                  required
                  placeholder="Nhập tên viết tắt"
                  class="mt-2"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Khu vực:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  class="mt-2 select-style"
                  size="sm"
                  v-model="$v.form.selectedArea.$model"
                  :options="listArea"
                  :state="validateState('selectedArea')"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >-- Khu vực --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  >Vui lòng chọn khu vực</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
             <b-col md="3">
              <b-form-group>
                <template>
                  <label>Tên hệ thống ngoài:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  size="sm"
                  v-model="$v.form.externalStoreName.$model"
                  :state="validateState('externalStoreName')"
                  required
                  placeholder="Nhập tên hệ thống ngoài"
                  class="mt-2"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Loại cửa hàng:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  class="mt-2 select-style"
                  size="sm"
                  v-model="$v.form.seletedStoreType.$model"
                  :options="[
                    { id: 1, name: 'Showroom DDV' },
                    { id: 2, name: 'Showroom VDD' },
                    { id: 3, name: 'Mobifone' },
                    { id: 4, name: 'Kho' },
                  ]"
                  :state="validateState('seletedStoreType')"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Loại cửa hàng --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  >Vui lòng chọn loại cửa hàng</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Số điện thoại:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  size="sm"
                  v-model="$v.form.phoneNumber.$model"
                  :state="validateState('phoneNumber')"
                  required
                  placeholder="Nhập số điện thoại"
                  class="mt-2"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.form.phoneNumber.required"
                  >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                >
                <b-form-invalid-feedback v-if="!$v.form.phoneNumber.numeric"
                  >Số điện thoại không hợp lệ</b-form-invalid-feedback
                >
              </b-form-group>
              
            </b-col>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Thành phố:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  class="mt-2 select-style"
                  size="sm"
                  v-model="$v.form.selected.$model"
                  :options="listCity"
                  :state="validateState('selected')"
                  value-field="id"
                  text-field="name"
                  v-on:change="changeCity"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >-- Thành phố --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  >Vui lòng chọn thành phố</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Địa chỉ:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="input-2"
                  size="sm"
                  v-model="$v.form.address.$model"
                  :state="validateState('address')"
                  required
                  placeholder="Nhập địa chỉ"
                  class="mt-2"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Nhập ít nhất 1 ký tự</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <template>
                  <label>Quận:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-select
                  size="sm"
                  class="mt-2 select-style"
                  id="input-3"
                  :state="validateState('selectedDistrict')"
                  v-model="$v.form.selectedDistrict.$model"
                  :options="listDistrict"
                  required
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >-- Quận --</b-form-select-option
                    >
                  </template>
                </b-form-select>
                <b-form-invalid-feedback
                  >Vui lòng chọn quận</b-form-invalid-feedback
                >
              </b-form-group>
            </b-col>
          </b-row>
        </v-form>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          type="submit"
          @click="addNewStore"
          >Lưu</b-button
        >
        <router-link :to="'/stores'">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { required, numeric } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { getToastInstance } from '@/utils/toastHelper';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      listCity: [],
      listDistrict: [],
      isNew: true,
      form: {
        name: '',
        shortName: '',
        externalStoreName: '',
        phoneNumber: '',
        address: '',
        selected: null,
        selectedDistrict: null,
        selectedCompany: null,
        selectedArea: null,
        seletedStoreType: null,
      },
      listCompany: [],
      listArea: [],
    };
  },
  validations: {
    form: {
      name: { required },
      shortName: { required },
      externalStoreName: { required },
      phoneNumber: { required, numeric },
      address: { required },
      selected: { required },
      selectedDistrict: { required },
      selectedCompany: { required },
      selectedArea: { required },
      seletedStoreType: { required },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.title = 'Thêm mới cửa hàng';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cửa hàng', route: '/stores' },
      { title: 'Thêm cửa hàng mới' },
    ]);
  },
  created() {
    this.fetchCity();
    this.fetchCompany();
    this.fetchArea();
  },
  methods: {
    ...getToastInstance(),
    reset() {
      this.$refs.form.reset();
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    addNewStore() {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const data = {
        data: {
          name: this.$v.form.name.$model,
          shortName: this.$v.form.shortName.$model,
          externalStoreName: this.$v.form.externalStoreName.$model,
          phoneNumber: this.$v.form.phoneNumber.$model,
          companyId: 3,
          areaId: this.$v.form.selectedArea.$model,
          address: this.$v.form.address.$model,
          district: this.$v.form.selectedDistrict.$model,
          city: this.$v.form.selected.$model,
          isActive: 1,
          deleted: 0,
          storeType: this.$v.form.seletedStoreType.$model,
        },
      };
      ApiService.post('stores/', data)
        .then(() => {
          this.makeToastSuccess('Tạo cửa hàng thành công');
          this.$router.push({
            path: '/stores',
          });
        })
        .catch(() => {
          this.makeToastFailure('Tạo cửa hàng không thành công');
        });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/')
        .then((data) => {
          this.listCity = data.data.data;
        })
        .catch(() => {});
    },
    getDistrict() {
      this.$v.form.selectedDistrict.$model = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.$v.form.selected.$model}/district`)
        .then((data) => {
          this.listDistrict = data.data.data;
        })
        .catch(() => {});
    },
    changeCity() {
      this.$v.form.selectedDistrict.$model = null;
      this.getDistrict();
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
      });
    },
    fetchArea: async function () {
      this.listArea = [];
      ApiService.setHeader();
      ApiService.get('/area').then((response) => {
        this.listArea = response.data.data;
      });
    },
  },
};
</script>
